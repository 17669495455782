import { svgs } from '@/utils/Images'
import { FC, SVGAttributes } from 'react'

export const LoadingSpinner: FC<SVGAttributes<SVGSVGElement>> = ({ className, ...rest }) => {
  return (
    <svg {...rest} className={`animate-spin ${className || ''}`} fill='none' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'>
      {svgs.refresh}
    </svg>
  )
}
export default LoadingSpinner
