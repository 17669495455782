import { ButtonWithLoading } from '@/components/buttons/ButtonWithLoading'
import { TextInput } from '@/components/FormElement'
import { useLanguage } from '@/hooks/Contexts'
import { API_URL, SOCIAL_MEDIA_FACEBOOK } from '@/utils/Consts'
import { svgs } from '@/utils/Images'
import { appUtils } from 'lib/AppUtils'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { FC, Fragment, HTMLAttributes, useEffect, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import validator from 'validator'

/**
 * Grid layout. Defaults to three colums per row.
 */
export const Footer: FC = () => {
  // Customize to your needs. The footer ui will be composed of this data.
  const { navigation: navStr } = useLanguage()
  const footerData: { headline: string; content: { title: string; link: string }[] }[] = useMemo(
    () => [
      {
        headline: navStr.my_account,
        content: [
          { title: navStr.my_account, link: '/user/dashboard' },
          { title: navStr.basket, link: '/cart' },
        ],
      },
      {
        headline: navStr.draws,
        content: [
          { title: navStr.winners, link: '/winners' },
          { title: navStr.draws, link: '/draws' },
          { title: navStr.entry_list, link: '/entries' },
        ],
      },
      {
        headline: navStr.about_us,
        content: [
          { title: navStr.contact, link: '/about-us' },
          { title: navStr.charity, link: '/charity' },
          { title: navStr.faq, link: '/faqs' },
        ],
      },
      {
        headline: navStr.information,
        content: [
          { title: navStr.cookie_policy, link: '/cookie-policy' },
          { title: navStr.privacy_policy, link: '/privacy-policy' },
          { title: navStr.terms_conditions, link: '/terms-conditions' },
          { title: navStr.terms_of_use, link: '/terms-of-use' },
          { title: navStr.acceptable_use_policy, link: '/acceptable-use-policy' },
        ],
      },
    ],
    [navStr]
  )

  return (
    <Fragment>
      <Subscriber extraCls='animate-fade-in-down' />

      <footer className='w-screen animate-fade-in-down bg-[linear-gradient(91deg,#1F1F1F_7%,#535353_97%)] bg-no-repeat'>
        <div className='main-container flex-col !justify-between py-4 md:flex-row md:py-10'>
          <div className='grid w-full grid-cols-2 lg:grid-cols-4'>
            {footerData.map((column, index) => (
              <div
                key={column.headline}
                className={`col-span-1 flex flex-col${index == 0 ? '' : ' even:ml-8 odd:lg:ml-8 '}${index < 2 ? '' : ' mt-8 lg:mt-0'}`}
              >
                <p className='mb-2 border-b border-cyan-v2 pb-2 font-bold text-amber-v1'>{column.headline}</p>
                {column.content.map(item => (
                  <Link key={item.title} href={item.link} passHref>
                    <a className='mt-2 cursor-pointer text-sm text-white/100 hover:text-cyan-v1 hover:underline'>{item.title}</a>
                  </Link>
                ))}
              </div>
            ))}
          </div>
          <div className='mt-6 flex w-full flex-row items-center self-baseline md:ml-12 md:mt-0 md:w-[unset] md:flex-col md:justify-between lg:ml-20 lg:w-min'>
            <svg className='h-auto w-84 text-white/100 md:block md:h-20 md:w-52' viewBox='5 2 200 75' fill='none' xmlns='http://www.w3.org/2000/svg'>
              {svgs.logo_brand}
            </svg>
            <SocialMediaLinks className='md:mt-3 md:justify-between' iconCls='ml-4 h-8 text-amber-v1 xs:ml-6 xs:h-10 md:ml-2 md:h-9' />
          </div>
        </div>
      </footer>

      <div className='w-screen animate-fade-in-down bg-black py-4 md:py-6'>
        <div className='main-container flex-col text-center text-white/100'>
          <span className='text-xs'>{navStr.accepted_payment_methods}</span>
          <div className='mt-4 grid w-full grid-cols-6 place-items-stretch sm:w-auto'>
            {[
              { payment: '/assets/payments/paypal.png', alt: 'Paypal Icon' },
              { payment: '/assets/payments/mastero.png', alt: 'Mastero Icon' },
              { payment: '/assets/payments/visa.png', alt: 'Visa Icon' },
              { payment: '/assets/payments/mastercard.png', alt: 'Mastercard Icon' },
              { payment: '/assets/payments/apple.png', alt: 'Apple Icon' },
              { payment: '/assets/payments/google.png', alt: 'Google Icon' },
            ].map(item => (
              <img
                className='pointer-events-none col-span-1 ml-auto mr-auto max-h-8 first:ml-[unset] last:mr-[unset] sm:ml-2 sm:mr-[unset]'
                key={item.payment}
                src={item.payment}
                alt={item.alt}
              />
            ))}
          </div>
          <span className='mt-4 whitespace-pre-wrap text-xs'>{navStr.copyright}</span>
        </div>
      </div>
    </Fragment>
  )
}

export const SocialMediaLinks: FC<HTMLAttributes<HTMLDivElement> & { iconCls?: string }> = ({ className, iconCls, ...rest }) => {
  return (
    <div {...rest} className={`flex w-full ${className || ''}`}>
      {[
        { key: 'facebook', icon: svgs.facebook, links: SOCIAL_MEDIA_FACEBOOK },
        { key: 'instagram', icon: svgs.instagram, links: 'https://www.instagram.com/rkingscompetitions/?hl=en' },
        { key: 'tiktok', icon: svgs.tiktok, links: 'https://www.tiktok.com/@rkingscompetitions?lang=en' },
      ].map((item, index) => (
        <a key={index} href={item.links} target='_blank' rel='noreferrer' className={`group ${iconCls || ''}`}>
          <svg className='h-full w-full' viewBox='0 0 36 36' xmlns='http://www.w3.org/2000/svg'>
            {svgs.gradient}
            {item.icon}
          </svg>
        </a>
      ))}
    </div>
  )
}

const Subscriber: FC<{ extraCls?: string }> = ({ extraCls }) => {
  const { errors: errStr, newsletter: nlStr } = useLanguage()

  const {
    register,
    formState: { errors, isDirty, isValid },
    handleSubmit,
    reset,
  } = useForm({ mode: 'onBlur', defaultValues: { name: '', email: '' } })

  const router = useRouter()

  useEffect(() => {
    const handleRouteChange = () => reset({ name: '', email: '' })
    router.events.on('routeChangeComplete', handleRouteChange)
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [router.pathname])

  const subscribe = data => {
    if (isDirty && isValid) {
      appUtils.postData(API_URL.subscribe, data, errStr, true).then(res => {
        if (res) {
          toast.success(nlStr.subscribe_success)
          reset(data)
        }
      })
    }
  }
  return (
    <form onSubmit={handleSubmit(subscribe)} className={`fluid-gray w-full bg-[linear-gradient(91deg,#414141_-8%,#323232_41%)] bg-no-repeat ${extraCls || ''}`}>
      <div className='container-gray flex flex-col items-center'>
        <span className='text-medium text-neutral-400'>{nlStr.sign_up}</span>
        <p className='mt-2 text-center text-xl font-medium text-white/100 md:text-2xl'>
          {nlStr.get_involved}
          <span className='text-amber-v1'>R Kings.</span>
        </p>
        <div className='mt-4 flex w-full flex-col items-stretch md:flex-row md:items-start md:justify-center'>
          <TextInput innerCls='!shadow-none !m-0 !py-0.5' input={{ placeholder: nlStr.name, ...register('name') }} />
          <TextInput
            outerCls='mt-4 md:ml-4 md:mt-0'
            innerCls='!shadow-none !m-0 !py-0.5'
            input={{
              placeholder: nlStr.email,
              ...register('email', {
                required: nlStr.email + errStr.required,
                validate: value => {
                  if (value) return validator.isEmail(value) || errStr.email_invalid
                },
              }),
              autoComplete: 'email',
            }}
            showValidated={!!errors?.email}
            valid={!errors?.email}
            tipMessage={errors?.email?.message?.toString?.() || null}
          />
          <ButtonWithLoading
            type='submit'
            isLoading={false}
            title={nlStr.join_now}
            className='btn-rd-outline btn-hover-amber btn-width ml-auto mr-auto mt-4 h-10 text-base font-medium text-white/100 md:ml-4 md:mr-0 md:mt-0 md:h-8'
          />
        </div>
      </div>
    </form>
  )
}

export default Footer
